import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDatas(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .get('budget', {
                        params: userData
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchData(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('budget', userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editData(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('budget/edit', userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        downloadDocument(ctx, {
            id
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`v1/budget/download/${id}`, {
                        responseType: 'blob'
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteDocument(ctx, {
            id
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`budget/filedocument/${id}`, {
                        responseType: 'blob'
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        deletefileData(ctx, {
            id
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('/budget/filedocument/+${id}')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },



        fetcheexpense(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/expense/getalldata')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
